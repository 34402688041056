import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { IconSize, ClickableIcon, IconActionPlus, IconGlobalCalendar, IconStatusError, IconStatusLikeFilled, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, Polymorphic } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "clickableicon"
    }}>{`ClickableIcon`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { ClickableIcon } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=2467%3A37'} storybook={'/?path=/story/icons-clickableicon--playground'} vue={'/components/HseClickableIcon/HseClickableIcon.html'} vueStorybook={'/?path=/story/icons-clickableicon--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`ClickableIcon — кликабельная иконка.`}</p>
    <p>{`Используется в тех случаях, когда `}<a parentName="p" {...{
        "href": "/src-components-icon-button"
      }}>{`IconButton`}</a>{` визуально слишком большой.`}</p>
    <p>{`ClickableIcon можно перекрашивать с помощью пропа `}<inlineCode parentName="p">{`color`}</inlineCode>{`. При смене цвета различные состояния меняют свой цвет соответственно основному цвету.`}</p>
    <p>{`У иконок три размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Для задания размера, передачи иконки, произвольных стилей и классов используйте проп `}<inlineCode parentName="p">{`iconProps`}</inlineCode>{`. `}<inlineCode parentName="p">{`iconProps`}</inlineCode>{` – объект, свойства которого передадутся компоненту `}<a parentName="p" {...{
        "href": "/src-components-icon"
      }}>{`Icon`}</a>{`.`}</p>
    <Polymorphic component="ClickableIcon" mdxType="Polymorphic" />
    <Playground __position={2} __code={'<ClickableIcon iconProps={{ icon: IconActionPlus }} />'} __scope={{
      props,
      DefaultLayout,
      IconSize,
      ClickableIcon,
      IconActionPlus,
      IconGlobalCalendar,
      IconStatusError,
      IconStatusLikeFilled,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ClickableIcon iconProps={{
        icon: IconActionPlus
      }} mdxType="ClickableIcon" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размер-иконки"
    }}>{`Размер иконки`}</h3>
    <p>{`У иконок три размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`iconProps`}</inlineCode>{` объект со свойством `}<inlineCode parentName="p">{`size`}</inlineCode></p>
    <Playground __position={3} __code={'<ClickableIcon iconProps={{ icon: IconGlobalCalendar, size: \'small\' }} />\n<br />\n<ClickableIcon iconProps={{ icon: IconGlobalCalendar, size: \'medium\' }} />\n<br />\n<ClickableIcon iconProps={{ icon: IconGlobalCalendar, size: \'large\' }} />'} __scope={{
      props,
      DefaultLayout,
      IconSize,
      ClickableIcon,
      IconActionPlus,
      IconGlobalCalendar,
      IconStatusError,
      IconStatusLikeFilled,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ClickableIcon iconProps={{
        icon: IconGlobalCalendar,
        size: "small"
      }} mdxType="ClickableIcon" />
  <br />
  <ClickableIcon iconProps={{
        icon: IconGlobalCalendar,
        size: "medium"
      }} mdxType="ClickableIcon" />
  <br />
  <ClickableIcon iconProps={{
        icon: IconGlobalCalendar,
        size: "large"
      }} mdxType="ClickableIcon" />
    </Playground>
    <h3 {...{
      "id": "цвет-иконки"
    }}>{`Цвет иконки`}</h3>
    <p>{`ClickableIcon можно перекрашивать с помощью пропа `}<inlineCode parentName="p">{`color`}</inlineCode>{`. При смене цвета различные состояния меняют свой цвет соответственно основному цвету`}</p>
    <Playground __position={4} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <ClickableIcon\n    iconProps={{ icon: IconStatusError, size: ClickableIcon.Size.large }}\n    color={\'red\'}\n  />\n  <Space size={Size.small_2x} vertical />\n  <ClickableIcon\n    iconProps={{ icon: IconStatusError, size: ClickableIcon.Size.large }}\n    color={\'red\'}\n    disabled\n  />\n  <Space size={Size.small_2x} vertical />\n  <ClickableIcon\n    iconProps={{ icon: IconStatusError, size: ClickableIcon.Size.large }}\n    color={\'red\'}\n    focused\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      IconSize,
      ClickableIcon,
      IconActionPlus,
      IconGlobalCalendar,
      IconStatusError,
      IconStatusLikeFilled,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <ClickableIcon iconProps={{
          icon: IconStatusError,
          size: ClickableIcon.Size.large
        }} color={'red'} mdxType="ClickableIcon" />
    <Space size={Size.small_2x} vertical mdxType="Space" />
    <ClickableIcon iconProps={{
          icon: IconStatusError,
          size: ClickableIcon.Size.large
        }} color={'red'} disabled mdxType="ClickableIcon" />
    <Space size={Size.small_2x} vertical mdxType="Space" />
    <ClickableIcon iconProps={{
          icon: IconStatusError,
          size: ClickableIcon.Size.large
        }} color={'red'} focused mdxType="ClickableIcon" />
  </div>
    </Playground>
    <h3 {...{
      "id": "произвольные-стили-иконки"
    }}>{`Произвольные стили иконки`}</h3>
    <p>{`Стили для иконки можно передать в свойство `}<inlineCode parentName="p">{`iconProps`}</inlineCode></p>
    <Playground __position={5} __code={'<ClickableIcon\n  iconProps={{ icon: IconStatusError, style: { transform: \'rotate(30deg)\' } }}\n/>'} __scope={{
      props,
      DefaultLayout,
      IconSize,
      ClickableIcon,
      IconActionPlus,
      IconGlobalCalendar,
      IconStatusError,
      IconStatusLikeFilled,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ClickableIcon iconProps={{
        icon: IconStatusError,
        style: {
          transform: 'rotate(30deg)'
        }
      }} mdxType="ClickableIcon" />
    </Playground>
    <h3 {...{
      "id": "произвольный-корневой-элемент"
    }}>{`Произвольный корневой элемент`}</h3>
    <p>{`ClickableIcon `}<a parentName="p" {...{
        "href": "/components#%D0%BF%D0%BE%D0%BB%D0%B8%D0%BC%D0%BE%D1%80%D1%84%D0%B8%D0%B7%D0%BC"
      }}>{`полиморфный`}</a>{`: вы можете задать произвольный корневой элемент, передав компонент или тег в проп `}<inlineCode parentName="p">{`component`}</inlineCode></p>
    <Playground __position={6} __code={'<ClickableIcon\n  component=\"a\"\n  href=\"#\"\n  iconProps={{ icon: IconStatusLikeFilled, size: IconSize.large }}\n/>'} __scope={{
      props,
      DefaultLayout,
      IconSize,
      ClickableIcon,
      IconActionPlus,
      IconGlobalCalendar,
      IconStatusError,
      IconStatusLikeFilled,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      Polymorphic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ClickableIcon component="a" href="#" iconProps={{
        icon: IconStatusLikeFilled,
        size: IconSize.large
      }} mdxType="ClickableIcon" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={ClickableIcon} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент в соответствии со значением `}<inlineCode parentName="p">{`component`}</inlineCode>{` (по умолчанию - `}<inlineCode parentName="p">{`button`}</inlineCode>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      